<template>
  <div class="promotion-activ">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap py-3 px-sm-8 px-3">
      <div class="font-weight-bold">Promo codes</div>
      <v-spacer></v-spacer>
      <v-btn :to="`/new-promo-code/${id.id}`" depressed class="primary rounded text-body-2" width="106" height="30">Add promo</v-btn>
    </div>
    <v-divider></v-divider>
    <div>
      <v-card
        tile
        flat
        color="transparent"
        v-if="!data"
        class="d-flex justify-center align-center"
        :min-height="`calc(100vh - 250px)`"
        height="100%"
      >
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 250px)`"
          class="d-sm-block d-none"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" height="42">
                <td>{{ item.code }}</td>
                <td>{{ item.group ? item.group.name : '' }}</td>
                <td>{{ item.usage_count }}</td>
                <td>{{ item.usage_limit }}</td>
                <td>
                  <v-btn @click="openModal(item)" small icon><img src="@/assets/icon/basket.svg" alt="basket" /></v-btn>
                </td>
              </tr>
            </tbody>
          </template>
          <!-- <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }}</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template> -->
        </v-data-table>
        <div class="d-block d-sm-none">
          <div v-for="item in data" :key="item.id" class="px-4 pt-2">
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Code</div>
              <div class="font-weight-medium f18">{{ item.code }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Group name</div>
              <div class="font-weight-medium f18">{{ item.group ? item.group.name : '' }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Usage count</div>
              <div class="font-weight-medium f18">{{ item.usage_count }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Usage limit</div>
              <div class="font-weight-medium f18">{{ item.usage_limit }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Delete</div>
              <v-btn @click="openModal(item)" small icon><img src="@/assets/icon/basket.svg" alt="basket" /></v-btn>
            </div>
            <v-divider></v-divider>
          </div>
          <!-- <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }}</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <v-dialog v-model="modal" max-width="460">
      <v-card flat outlined class="pa-8 pt-5">
        <div class="text-center text-h6 font-weight-bold mb-5">Do you really want remove code {{ modalData.code }}</div>
        <div class="text-end mt-2">
          <v-btn @click="removeItem" depressed class="primary rounded text-body-2" width="106" height="30">Remove</v-btn>
          <v-btn @click="modal = false" depressed class="ms-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      modalData: {},
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Code', value: 'code', sortable: false },
        { text: 'Group name', value: 'group', sortable: false },
        { text: 'Usage count', value: 'usage_count', sortable: false },
        { text: 'Usage limit', value: 'usage_limit', sortable: false },
        { text: 'Delete', value: 'delete', sortable: false },
      ],
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openModal(i) {
      this.modal = true;
      this.modalData = i;
    },
    async removeItem() {
      await this.$store.dispatch('removePromotionCode', this.modalData.id).then(() => {
        this.modal = false;
      });
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getPromotionCodeList', { page: this.page })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.promotionCodeList;
    },
    id() {
      return this.$store.getters.promotionItem;
    },
  },
};
</script>
