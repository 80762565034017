<template>
  <div class="promotion-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/promotions">Promotions</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.name_en }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu py-2" flat>
          <div
            v-for="item in menuItems"
            :key="item.title"
            :class="link == item.link ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu(item.link)"
            class="item-menu_item link"
          >
            <div>{{ item.title }}</div>
          </div>
        </v-card>
        <v-divider style="z-index: 90" vertical></v-divider>
        <div class="width100" v-if="link == 'campaign-details'">
          <Details />
        </div>
        <div class="width100" v-if="link == 'promo-codes'">
          <Codes />
        </div>
        <div class="width100" v-if="link == 'activations'">
          <Activations />
        </div>
        <div class="width100" v-if="link == 'card-bins'">
          <CardBins />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Details from '@/components/promotion/Details';
import Codes from '@/components/promotion/Codes';
import Activations from '@/components/promotion/Activations';
import CardBins from '@/components/promotion/CardBins';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Details,
    Codes,
    Activations,
    CardBins,
  },
  data() {
    return {
      link: this.$route.params.tab,
      menuItems: [
        { link: 'campaign-details', title: 'Campaign details' },
        { link: 'promo-codes', title: 'Promo codes' },
        { link: 'activations', title: 'Activations' },
        { link: 'card-bins', title: 'Card bins' },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('getPromotionItem');
  },
  methods: {
    clickMenu(l) {
      this.link = l;
      this.$router.push(`/promotions/${this.$route.params.promotionId}/${l}`);
    },
  },
  computed: {
    data() {
      return this.$store.getters.promotionItem;
    },
  },
  destroyed() {
    this.$store.dispatch('setPromotionItem');
    this.$store.dispatch('setPromotionCodeList');
    this.$store.dispatch('setPromotionActivations');
    this.$store.dispatch('setPromotionGroupList');
    this.$store.dispatch('setPromotionGroup');
    this.$store.dispatch('setCardBinsList');
  },
};
</script>

<style lang="scss">
.promotion-item {
  table thead tr th {
    background: initial !important;
  }
  table tbody tr:hover {
    background: initial !important;
  }
}
</style>
