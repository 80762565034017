<template>
  <div class="promotion-bin" :key="key">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap py-3 px-sm-8 px-3">
      <div class="font-weight-bold">Limit to payment card bins</div>
      <v-spacer></v-spacer>
      <v-btn @click="modalItem = true" depressed class="primary rounded text-body-2" width="106" height="30">Add bin</v-btn>
    </div>
    <v-divider></v-divider>
    <div>
      <v-card
        tile
        flat
        color="transparent"
        v-if="!data.length"
        class="d-flex justify-center align-center"
        :min-height="`calc(100vh - 250px)`"
        height="100%"
      >
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 250px)`"
          class="d-sm-block d-none"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
                height="42"
                @click="(modalData = Object.assign({}, item)), (modalItem = true)"
                class="link"
              >
                <td>{{ item.title }}</td>
                <td>{{ item.bin }}</td>
                <td>
                  <v-btn @click.stop="openModal(item)" small icon><img src="@/assets/icon/basket.svg" alt="basket" /></v-btn>
                </td>
              </tr>
            </tbody>
          </template>
          <!-- <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }}</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template> -->
        </v-data-table>
        <div class="d-block d-sm-none">
          <div
            v-for="item in data"
            :key="item.id"
            class="px-4 pt-2 link"
            @click="(modalData = Object.assign({}, item)), (modalItem = true)"
          >
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Title</div>
              <div class="font-weight-medium f18">{{ item.title }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Bin</div>
              <div class="font-weight-medium f18">{{ item.usagbine_limit }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Remove</div>
              <v-btn @click.stop="openModal(item)" small icon><img src="@/assets/icon/basket.svg" alt="basket" /></v-btn>
            </div>
            <v-divider></v-divider>
          </div>
          <!-- <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }}</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <v-dialog v-model="modal" max-width="460">
      <v-card flat outlined class="pa-8 pt-5">
        <div class="text-center text-h6 font-weight-bold mb-5">Do you really want remove code {{ modalData.title }}</div>
        <div class="text-end mt-2">
          <v-btn @click="removeItem" depressed class="primary rounded text-body-2" width="106" height="30">Remove</v-btn>
          <v-btn @click="modal = false" depressed class="ms-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalItem" max-width="460">
      <v-card flat outlined class="pa-7 pt-5">
        <div class="text-h6 font-weight-bold mb-5">{{ modalData.id ? 'Update card bin' : 'Add card bin' }}</div>
        <div class="mb-2">Card title</div>
        <v-text-field
          class="field46"
          v-model="modalData.title"
          :error-messages="titleErrors"
          placeholder="Title"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="mb-2">Card bin</div>
        <v-text-field
          class="field46"
          v-model="modalData.bin"
          :error-messages="binErrors"
          placeholder="Bin"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="text-end mt-2">
          <v-btn @click="modalItem = false" depressed class="rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn
            @click="modalData.id ? updateItem() : createItem()"
            depressed
            class="ms-3 primary rounded text-body-2"
            width="106"
            height="30"
          >
            {{ modalData.id ? 'Save' : 'Create' }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      key: 1,
      modal: false,
      modalItem: false,
      modalData: {},
      headers: [
        { text: 'Title', value: 'title', sortable: false },
        { text: 'Bin', value: 'bin', sortable: false },
        { text: 'Remove', value: 'delete', sortable: false },
      ],
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'create') {
        this.$notify({
          title: 'Done!',
          message: 'Card bin was successfully created',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Card bin was successfully updated',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Card bin was successfully removed',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openModal(i) {
      this.modal = true;
      this.$nextTick(() => {
        this.modalData = Object.assign({}, i);
      });
    },
    async removeItem() {
      await this.$store.dispatch('removeCardBinsItem', this.modalData.id).then(() => {
        this.notifi('remove');
        const idx = this.data.findIndex((i) => i.id == this.modalData.id);
        this.data.splice(idx, 1);
        this.modal = false;
      });
    },
    async createItem() {
      this.error = [];
      await this.$store
        .dispatch('cteateCardBinsItem', this.modalData)
        .then((res) => {
          this.notifi('create');
          this.data.unshift(res.data);
          this.modalItem = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async updateItem() {
      this.error = [];
      await this.$store
        .dispatch('updateCardBinsItem', { id: this.modalData.id, data: this.modalData })
        .then((res) => {
          this.notifi('update');
          const idx = this.data.findIndex((i) => i.id == this.modalData.id);
          this.data[idx] = res.data;
          ++this.key;
          this.modalItem = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async getData() {
      await this.$store.dispatch('getCardBinsList').then(() => {
        this.nothing = false;
      });
    },
  },
  watch: {
    modalItem(val) {
      if (!val) {
        this.error = [];
        this.modalData = Object.assign({}, {});
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.cardBinsList;
    },
    titleErrors() {
      const errors = [];
      this.error.find((item) => item == 'title__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    binErrors() {
      const errors = [];
      this.error.find((item) => item == 'bin__required') && errors.push('Please enter bin');
      this.error.find((item) => item == 'bin__invalid') && errors.push('Provided bin is not valid');
      return errors;
    },
  },
};
</script>
